export default function SignupMail () {
  const fpoStyle = {
    position: 'absolute',
    left: '-5000px'
  }
  return (
    <div className="mail-signup">
      <link href="//cdn-images.mailchimp.com/embedcode/slim-10_7_dtp.css" rel="stylesheet" type="text/css" />
        {/* <style type="text/css">
          #mc_embed_signup{
            background:#fff;clear:left; 
            font:14px Helvetica,Arial,sans-serif; 
          }
        </style> */}
        <div id="mc_embed_signup">
          <form action="https://nevoterap.us14.list-manage.com/subscribe/post?u=6b36e17f3491f0ce1fab1e867&amp;id=c75fea9bb6" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
              <label htmlFor="mce-EMAIL">Sign up for updates</label>
              <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="email address" required />
              <div style={fpoStyle} aria-hidden="true">
                <input type="text" name="b_6b36e17f3491f0ce1fab1e867_c75fea9bb6" tabIndex="-1" />
              </div>
              <div className="optionalParent">
                <div className="clear foot">
                  <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                  <p className="brandingLogo">
                     &nbsp;
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>


    </div>
  )
}