import {EfLogo} from './components/logos'
import Footer from './components/Footer'
import Header from './components/Header'
import DataLinks from './components/DataLinks'
import SocialMediaBlock from './components/social-media'
import PollWorkerBlock from './components/PollWorkerBlock'
import SignupMail from './components/SignupMail'

const socialLinks = [
  // {title: 'Telegram', type:'telegram', link:'https://t.me/NEAuditChat'},
  {title: 'Telegram', type:'telegram', link:'https://t.me/NebraskaVAP'},
  {title: 'Facebook', type:'facebook', link: 'https://www.facebook.com/NEVoterAP'},
  {title: 'Twitter', type:'twitter', link: 'https://twitter.com/NeVoterAP'},
  {title: 'Gettr', type:'gettr', link: 'https://gettr.com/user/nevoterap'},
  {title: 'Gab', type:'gab', link: 'https://gab.com/NeVoterAP'},
  {title: 'Rumble', type:'rumble', link: 'https://rumble.com/c/c-1270342'},
  {title: 'Soundcloud', type:'soundcloud', link: 'https://soundcloud.com/nvap'},
]

export const Intro = () => {
  return (
    <div className='intro-page'>
      <Header />
      <div className="main-content">
        
        <section className='primary-intro'>
          <div className='col-1'>
            <p className='intro-text'>
              Our mission is to educate Nebraskans and Nebraska Legislators on our local 
              government election processes and issues, unite Nebraskans across our state, 
              inclusive of all parties and political beliefs, call our state and federal leaders 
              to action, organize those actions that lead to full forensic audits across Nebraska 
              and if fraud is discovered, repeat the election to preclude propagating and thus 
              obviating any fraud.
            </p>

            <PollWorkerBlock />

            <h2>Get Involved</h2>

            <div>
              <p>
                Volunteers are needed to help verify the irregularities found. One key way this is done is through voter canvassing, 
                with teams analyzing the county and state records and voter rolls, and others going door-to-door to identify whether 
                the records match the actual residents living at the address.
              </p>

              <br />
              <hr />
              <p>

                To join the grassroots efforts in pursuing election integrity and audits of the 2020 election in Nebraska, 
                join us on <a href="https://t.me/NEAuditChat" target="_blank" rel="noopener noreferrer">Telegram</a>.
              </p>
              <hr />
            </div>
            
            <SocialMediaBlock className={'social-media-block'} links={socialLinks} />

            <div className='contact-area'>
              Do you have Information on Voter Election Fraud in Nebraska?
              Or
              Do you want to help our all-volunteer team?

              Meet us and report it at ‘Nebraska First Chat’ on Telegram to @LarryOrtega
            </div>

          </div>

          <div className='col-2'>
            <DataLinks />
            
            <br />
            <p>
              <a href="https://electionfraud20.org/fraud-summary-by-state/nebraska/" target="_blank" rel="noopener noreferrer">
                <EfLogo />
              </a>
            </p>

            <SignupMail />
          </div>
        </section>

        
        <section>
          
        </section>

      </div>
      <Footer />
    </div>
  )
}

export default Intro