import {
  FaFilePdf, 
  FaLink,
  FaTelegram,
  FaFacebook,
  FaTwitterSquare,
  FaSoundcloud,
} from 'react-icons/fa'

import gabLogoUrl from '../../img/logos/gab-icon.svg'
import rumbleLogoUrl from '../../img/logos/rumble_icon_svg.svg'
import gettrLogoUrl from '../../img/logos/gettr_icon_svg.svg'

import '../../style/social.scss'

const GabLogo = () => {
  return (
    <img src={gabLogoUrl} />
  )
}

const GettrLogo = () => {
  return (
    <img src={gettrLogoUrl} />
  )
}

const RumbleLogo = () => {
  return (
    <img src={rumbleLogoUrl} />
  )
}

export const SocialLogo = ({type}) => {
  let logoIcons = {
    'pdf': FaLink,
    'twitter': FaFilePdf,
    'telegram': FaTelegram,
    'facebook': FaFacebook,
    'twitter': FaTwitterSquare,
    'soundcloud': FaSoundcloud,
    'gab': GabLogo,
    'gettr': GettrLogo,
    'rumble': RumbleLogo,
  }

  let FoundLogo = (logoIcons[type]) ? logoIcons[type] : FaLink
  return (
    <i className={'social-logo'}>
      <FoundLogo />
    </i>
  )
}

export const SocialMediaLink = ({className, link, href}) => {
  return (
    <a className={`social-media-link ${className || ''}`} href={href}>
      <SocialLogo {...link} type={link.type} /> 
      <span>{link.title}</span>
    </a>
  )
}


export const SocialMediaBlock = ({links, className, href}) => {
  return (
    <div className={`social-media-block ${className || ''}`} >
      <div>
        <a href="https://lnk.bio/NEVoterAP" className='link-tree-link'><FaLink /> Checkout out our link tree for all social media links</a>
      </div>

      <div className='social-media-links-content'>
        {links.map((link, id) => {
          return (
            <div key={`link_${link.type}_${id}`} className={`social-media-link-wrapper type-${link.type}`} >
              <SocialMediaLink link={link} href={link.link} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SocialMediaBlock