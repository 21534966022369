import FAQ from './FAQ'

export default () => {
  return (
    <footer>
      <div className='inner'>

      
        <section>
          <FAQ />
        </section>
        <section>
        
          <h3>Want to dive deeper?</h3>
          <div>
            <ul>
              <li>
                <a href="https://americanvotersalliance.org" target="_blank" rel="noopener noreferrer">The American Voter's Alliance</a> 
                
                provides a great national overview with many reports, videos and detailed legal submissions.
              </li>
              <li>
                <a href="https://election-integrity.info" target="_blank" rel="noopener noreferrer">Election-Integrity.info</a> 
                provides over 25 thoroughly-researched, scientifically-approached reports.
              </li>
              <li>
                <a href="https://useip.org/" target="_blank" rel="noopener noreferrer">The US Election Integrity Plan</a> 
                contains further news, analysis, videos and practical guides to getting involved.
              </li>
              <li>
                <a href="https://hereistheevidence.com" target="_blank" rel="noopener noreferrer">HereIsTheEvidence.com</a> 
                and <a href="https://electionevidence.com" target="_blank" rel="noopener noreferrer">ElectionEvidence.com</a> 
                have compiled detailed databases of hundreds of items of election fraud evidence, by state.
              </li>
          
            </ul>
          </div>
        </section>
      </div>
    </footer>
  )
}