import {FaFilePdf} from 'react-icons/fa'

const DataLink = ({title, link}) => {
  return (
    <li>
      <a href={link} target="_blank" rel="noopener noreferrer">{title}</a>
    </li>
  )
}

export default () => {
  const pdfSlideshowURL = '/docs/NebVAPPresentation17Dec21v15.pdf'
  const janAuroraResponseURL = '/docs/LTE_January_Response_Aurora.pdf'
  const affidavitURL = '/docs/NE-Combined-VV-Affidavit.pdf'
  const NAGOpresentation = 'https://tinyurl.com/58mfvxud' // https://rumble.com/vr9cqt-nebraska-voter-accuracy-project-presentation-to-n.a.g.o..html
  const EAndSCorruptionDoc = '/docs/Election-Systems-and-Software-ESS-Corruption-07162019.pdf'

  const links = [
    {link: EAndSCorruptionDoc, title: 'E&S Corruption Research'},
    {link: pdfSlideshowURL, title: 'An Overview of What We’ve Found'},
    {link: 'https://electionfraud20.org/trend-analysis/historical-county-analysis-registration-data/', title: 'How to Predict Election Results using Registration Data'},
    {link: janAuroraResponseURL, title: 'January Letter to the Editor, Aurora'},
    {link: NAGOpresentation, title: 'Nebraska Voter Accuracy Project Presentation to N.A.G.O.'},
    {link: 'https://fatherlyadviceandrants.com/2021/08/04/expert-seth-keshel-releases-national-2020-election-fraud-numbers-8-1-million-excess-votes-found/', title: 'All Fifty States Trend Analysis Issues (including Nebraska)'},
    {link: 'https://electionfraud20.org/fraud-summary-by-state/nebraska', title: 'Election Fraud in Nebraska'},
    {link: 'https://electionfraud20.org/dr-frank-reports/', title: 'Inflated Voter Registration and Turnout – 50 States, including Nebraska'},
    {link: 'https://rumble.com/vkjopd-introduction-to-nebraskas-key-w-dr.-douglas-frank-phd-omaha-ne.html', title: 'Intro To Nebraska’s Key, Cheating State Wide, the Same in Every County – Liberty Ladies of Omaha'},
    {link: 'https://www.heritage.org/electionscorecard/', title: 'Heritage Foundation Election Integrity Scorecard – Nebraska in bottom 10 of USA'},
  ]

  const linkItems = links.map(({link, title}, id) => {
    return (
      <DataLink key={`link_${id}`} link={link} title={title} />
    )
  })

  return (
    <div className="data-links">
      <div className='download-pdf-widget'>
        <a href={affidavitURL} target="_blank" rel="noopener noreferrer">
          <span className='icon'><FaFilePdf /></span>
          <span className='text'>Download Voter Affidavit</span>
        </a>
      </div>
      <ul>
        {linkItems}
      </ul>
    </div>
  )
}