import Intro from './Intro'

export function App() {
  return (
    <>
      <main>
        <Intro />
      </main>
    </>
  )
}