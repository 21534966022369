const FaqLink = ({title, link}) => {
  return (
    <li>
      <a href={link} target="_blank" rel="noopener noreferrer">{title}</a>
    </li>
  )
}


export default () => {
  const links = [
    {link: 'https://electionfraud20.org/faqs/why-do-people-believe-election-was-rigged/', title: 'Why do people believe the 2020 election was rigged?'},
    {link: 'https://electionfraud20.org/faqs/was-there-widespread-fraud/', title: 'Was there really widespread election fraud?'},
    {link: 'https://electionfraud20.org/faqs/voting-system-test-laboratories-vstl/', title: 'What is a Voting System Test Laboratory (VSTL)?'},
    {link: 'https://electionfraud20.org/in-detail/vote-dumps-spikes/', title: 'What caused the vote dumps (spikes) during the 2020 ballot counting?'},
    {link: 'https://electionfraud20.org/faqs/voices-promoting-election-integrity/', title: 'Where can I find organisations promoting election integrity?'},
    {link: 'https://electionfraud20.org/faqs/reliable-election-news/', title: 'Where can I find reliable news about election integrity and audits?'},
    {link: 'https://electionfraud20.org/faqs/legislators-supporting-forensic-audits/', title: 'Which state legislators support forensic audits and election integrity?'},
    {link: 'https://electionfraud20.org/in-detail/dominion-voting-machines/', title: 'Were the Dominion voting machines involved in election irregularities?'},
    {link: 'https://electionfraud20.org/in-detail/capitol-riots/', title: 'Were there also irregularities during the January 6 protests at the US Capitol?'},
    {link: 'https://electionfraud20.org/in-detail/2020-election-fraud-documentaries/', title: 'What documentaries have covered the US 2020 election integrity issues?'},
  ]
  const linkItems = links.map(({link, title}, id) => {
    return (
      <FaqLink key={`link_${id}`} link={link} title={title} />
    )
  })

  return (
    <div>
      <h3>Frequently Asked Questions</h3>
      <ul>
        {linkItems}
      </ul>
    </div>
  )
}