import logo from '../../img/nvap_logo_full_900w.png'

export default () => {
  return (
    <header>
      <div className='header-graphic'>
        <div className='inner'>

          <h1 className='logo-header'>
            <img src={logo} id="main-logo" />
          </h1>
        </div>
      </div>

    </header>
  )
}