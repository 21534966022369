import {FaFilePdf} from 'react-icons/fa'

import '../../style/callout_block.scss'

export default function PollWorkerBlock () {
  const pollWorkerDoc = '/docs/Nebraska_County_Clerks_and_Election_Commissioners.xlsx'
  
  return (
    <div className="poll-worker-block callout-block ">
      <h3>Help Nebraska secure the May Primaries: <small>Sign-up to become a Poll Worker</small></h3>
      <div className='download-pdf-widget'>
        <p>
          Poll workers are the first line of defense for election integrity, by becoming one you are helping 
          to ensure a free, fair, and secure elections.  Our voting process is transparent, and we must ensure 
          voters choices are private. We the People must protect our election process.
        </p>
 
        <h4>How to become a Poll Worker:</h4>
        <ul>
           <li>Contact your county clerk or election commissioner.</li>
           <li>Receive training per each counties process</li>
           <li>Apply today, many counties have deadline approaching for the May Primaries</li>
        </ul>

        
        <h4>Once trained Responsibilities are simple:</h4>
        <ul>
           <li>Open the poll: Polling begins at 8:00am</li>
           <li>Administer elections: properly and fairly</li>
           <li>Assist Voters: answer questions through the voting process</li>
           <li>Close the poll: Polls close at 8:00pm</li>
        </ul>

        <p>
          <a href={pollWorkerDoc} className='btn-lg'>Find your county information</a>
        </p>
        
      </div>

    </div>
  )
}